import { useRoutes } from 'react-router-dom'
import { useAuth } from '@/features/auth'
import { useUser } from '@/features/user'
import { getRootRoutes } from '@/routes'

export const RootNavigator = () => {
  const { isLoggedIn } = useAuth()
  const { user } = useUser()

  return useRoutes(getRootRoutes(isLoggedIn, user))
}
