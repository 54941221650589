import { Navigate } from 'react-router-dom'
import { BasicLayout, PanelLayout, PrivateLayout } from '@/components/Layouts'
import { ROLE } from '@/features/user/types'
import { PanelSwitch, RouteGuard } from '@/routes'
import { getBookRoutes, getGeneralRoutes, getProfileCompleteRoutes } from './routes'

const mapChildrenRoutes = (routs: Array<any>, user: object | null) => [
  ...routs.map(({ path, index, element, roles, guards }, i) => {
    return {
      key: `${i}-${path}`,
      path,
      index,
      element: (
        <RouteGuard roles={roles} guards={guards} user={user}>
          {element}
        </RouteGuard>
      ),
    }
  }),
]

export const getPrivateRoutes = (isLoggedIn: boolean, user: any | null) => {
  return [
    {
      path: '/*',
      element: isLoggedIn ? (
        <PrivateLayout />
      ) : (
        <Navigate to={!user || user.role === ROLE.Client ? `/` : `/babysitter`} replace />
      ),
      children: [
        {
          index: isLoggedIn,
          element: <PanelSwitch user={user} />,
        },
        {
          element: <PanelLayout />,
          children: [...mapChildrenRoutes(getGeneralRoutes(), user)],
        },
        {
          element: <PanelLayout useOpacity={true} goToBookNow={true} />,
          children: [...mapChildrenRoutes(getBookRoutes(), user)],
        },
        {
          path: 'profile/complete/*',
          element: (
            <RouteGuard roles={[ROLE.Babysitter]} user={user}>
              <BasicLayout header={false} />
            </RouteGuard>
          ),
          children: mapChildrenRoutes(getProfileCompleteRoutes(), user),
        },
        {
          path: '*',
          element: <Navigate to={`/`} replace />,
        },
      ],
    },
  ]
}
