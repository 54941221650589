import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
//import { useTranslation } from 'react-i18next'
import { Space } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { BookingTimetableFormConnector } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/BookingTimetableFormConnector'
import { LanguagesFormSection } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/Forms/CareDetailsForm/FormSections'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
//import { useBookingPeriod } from '../../hooks'
import { BookingLocation } from '../../types'
import { BookingPeriodFormSection } from './BookingPeriodFormSection'
import { LocationFormSection } from './LocationFormSection/LocationFormSection'
import { PermanentNannyFormSection } from './PermanentNannyFormSection/PermanentNannyFormSection'
import { TimetableSection } from './TimetableSection'
import { TypeFormSection } from './TypeFormSection/TypeFormSection'

interface IProps {
  location: BookingLocation | null
  locationNotFound: boolean
  onLocationNotFound: (notFound: boolean) => void
  onLocationChange: (data: BookingLocation | null) => void
}

export const DataForm = ({
  location,
  onLocationChange,
  locationNotFound,
  onLocationNotFound,
}: IProps) => {
  const { watch, setValue } = useFormContext()
  const watchType = watch('type')
  const watchLivingIn = watch('living_in')
  const {
    appState: { mobileView },
  } = useAppState()

  useEffect(() => {
    if (watchType === BOOKING_TYPE.REGULAR) {
      setValue('timetable.same_weeks_schedule', true)
      setValue('timetable.same_days_time', true)
    } else {
      setValue('timetable.same_weeks_schedule', false)
      setValue('timetable.same_days_time', false)
    }
  }, [watchType])

  return (
    <>
      <BookingTimetableFormConnector />

      {(watchType == BOOKING_TYPE.ONETIME || watchType == BOOKING_TYPE.REGULAR) && (
        <LocationFormSection
          onLocationChange={onLocationChange}
          locationNotFound={locationNotFound}
          onLocationNotFound={onLocationNotFound}
        />
      )}

      {(watchType == BOOKING_TYPE.ONETIME || watchType == BOOKING_TYPE.REGULAR) && (
        <TypeFormSection />
      )}

      <BookingPeriodFormSection />

      {watchType !== BOOKING_TYPE.PERMANENT_NANNY && <TimetableSection />}

      {watchType === BOOKING_TYPE.PERMANENT_NANNY && <PermanentNannyFormSection />}
      {/*valid && watchType === BOOKING_TYPE.LIVE_IN && <TimetableSectionWeek />*/}

      {/*<InfoFormSection />*/}

      {watchType != BOOKING_TYPE.ONETIME && watchType != BOOKING_TYPE.REGULAR && (
        <LocationFormSection
          onLocationChange={onLocationChange}
          locationNotFound={locationNotFound}
          onLocationNotFound={onLocationNotFound}
        />
      )}
      {watchType != BOOKING_TYPE.ONETIME && watchType != BOOKING_TYPE.REGULAR && <Space h={'md'} />}
      <LanguagesFormSection boldTitle={true} />

      <Space h={'xl'} />
      {mobileView && <Space h={'xl'} />}
    </>
  )
}
