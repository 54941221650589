import { Navigate } from 'react-router-dom'
import { BasicLayout } from '@/components/Layouts'
import { Confirmed } from '@/features/booking/routes/confirmed'
import { New } from '@/features/booking/routes/new'
import { RegularConfirmed } from '@/features/booking/routes/regularConfirmed'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { ROLE } from '@/features/user'

//config
export const getPrivateBookingRoutes = (isLoggedIn: boolean, user: any | null) => {
  return [
    {
      path: '/booking',
      element: isLoggedIn ? (
        <BasicLayout header={false} loadSelects />
      ) : (
        <Navigate to={!user || user.role === ROLE.Client ? `/` : `/babysitter`} replace />
      ),
      children: [
        {
          path: 'onetime/new',
          element: <New bookingType={BOOKING_TYPE.ONETIME} step={0} />,
        },
        {
          path: 'regular/new',
          element: <New bookingType={BOOKING_TYPE.REGULAR} step={0} />,
        },
        {
          path: 'animation/new',
          element: <New bookingType={BOOKING_TYPE.ANIMATION} step={0} />,
        },
        {
          path: 'nanny/new',
          element: <New bookingType={BOOKING_TYPE.PERMANENT_NANNY} step={0} />,
        },
        {
          path: 'onetime/checkout',
          element: <New bookingType={BOOKING_TYPE.ONETIME} step={1} />,
        },
        {
          path: 'regular/checkout',
          element: <New bookingType={BOOKING_TYPE.REGULAR} step={1} />,
        },
        {
          path: 'animation/checkout',
          element: <New bookingType={BOOKING_TYPE.ANIMATION} step={1} />,
        },
        {
          path: 'nanny/checkout',
          element: <New bookingType={BOOKING_TYPE.PERMANENT_NANNY} step={1} />,
        },
        {
          path: 'onetime/care-details',
          element: <New bookingType={BOOKING_TYPE.ONETIME} step={2} />,
        },
        {
          path: 'regular/care-details',
          element: <New bookingType={BOOKING_TYPE.REGULAR} step={1 /*2*/} />,
        },
        {
          path: 'animation/care-details',
          element: <New bookingType={BOOKING_TYPE.ANIMATION} step={1 /*2*/} />,
        },
        {
          path: 'nanny/care-details',
          element: <New bookingType={BOOKING_TYPE.PERMANENT_NANNY} step={1 /*2*/} />,
        },
        {
          path: 'confirmed',
          element: <Confirmed />,
        },
        {
          path: 'regular/confirmed',
          element: <RegularConfirmed />,
        },
        /*{
      path: '/booking/checkout',
      element: (
        <BasicLayout header={false} loadSelects>
          <Checkout />
        </BasicLayout>
      ),
      children: [],
    },*/
      ],
    },
  ]
}
