import { Link } from 'react-router-dom'
import { Anchor } from '@mantine/core'
import { AppLangPicker, LogoIcon } from '@/components/Elements'
import { Support } from '@/features/header/layout/Support'
import useStyles from './Header.styles'

interface IProps {
  lang?: boolean
  bookNow?: boolean
}

export const Header = ({ lang = false, bookNow = false }: IProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.header}>
      <Anchor component={Link} to={'/'}>
        <LogoIcon size={'lg'} />
      </Anchor>

      <div className={classes.headerRight}>{bookNow && <Support />}</div>
      {lang && <AppLangPicker />}
    </div>
  )
}
