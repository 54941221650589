import { useMantineTheme } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { ModalOrDialogSwitch } from '@/components/Elements/MobileWebSwitchs'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { InfoEvent } from '@/features/header/layout/Info/InfoEvent'
import { usePopup } from '@/hooks'
import useStyles from './Info.styles'

interface IProps {
  bookingType: BOOKING_TYPE
  size?: number
}
export const Info = ({ size = 32, bookingType }: IProps) => {
  const theme = useMantineTheme()
  const { visible, open, close } = usePopup()

  const {
    appState: { mobileView },
  } = useAppState()

  const { classes } = useStyles()

  return (
    <>
      <div id="icons" className={classes.iconWhite} onClick={open}>
        <Icon
          name={'info'}
          stroke={1}
          size={size}
          viewPort={30}
          className={classes.linkIcon}
          color={theme.colors[theme.primaryColor][theme.fn.primaryShade()]}
        />
      </div>
      <ModalOrDialogSwitch
        title={' '}
        opened={visible}
        headerBorder={false}
        onClose={close}
        rightIcon="help"
        fromBooking={true}
        background={mobileView ? 'primary' : 'white'}
      >
        <InfoEvent bookingType={bookingType} />
      </ModalOrDialogSwitch>
    </>
  )
}
