import { useMantineTheme } from '@mantine/core'
import { Icon } from '@/components/Elements'
import useStyles from './Support.styles'

interface IProps {
  size?: number
  stroke?: number
}
export const Support = ({ size, stroke }: IProps) => {
  const theme = useMantineTheme()

  const { classes } = useStyles()

  const openSupport = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.zE('messenger', 'show')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.zE('messenger', 'open')
  }
  return (
    <div id="icons" className={classes.iconWhite} onClick={openSupport}>
      <div className="d-flex">
        <div className="support-border">
          <Icon
            name={'support'}
            stroke={stroke}
            size={size}
            className={classes.linkIcon}
            color={theme.colors[theme.primaryColor][theme.fn.primaryShade()]}
          />
        </div>
      </div>
    </div>
  )
}
