import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { WhatsappIcon } from 'react-share'
import { Grid, Group, Text } from '@mantine/core'
import useStyles from '@/components/Elements/Tabs/BottomTabs/BottomTabs.styles'
import { Support } from '@/features/header/layout/Support'

export const BottomTabsBookNow = () => {
  const whatsapp = process.env.REACT_APP_WHATSAPP_BABYSISTERS
  const { classes } = useStyles()
  const { t } = useTranslation()

  const messageContentWhatsapp = () => {
    const message = 'https://api.whatsapp.com/send?phone=' + whatsapp
    window.open(message, '_blank', 'noreferrer')
  }

  return (
    <div className="d-flex flex-row w-100">
      <Group position="apart" className={classes.bookNow}>
        <Grid justify="space_between" align="center" className="w-100">
          <Grid.Col span={2}>
            <Support size={32} stroke={1} />
          </Grid.Col>
          <Grid.Col span={8}>
            <Text fz="sm">{ReactHtmlParser(t('book.help'))}</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text fz="sm">{t('book.or')}</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <div className="cursor-pointer" onClick={() => messageContentWhatsapp()}>
              <WhatsappIcon size={42} round />
            </div>
          </Grid.Col>
        </Grid>
      </Group>
    </div>
  )
}
