import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  border: {
    borderBottom: '1px solid lightgrey',
  },
  noborder: {
    borderBottom: '0px solid lightgrey',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: '90px',
    flexWrap: 'wrap',
    padding: '10px 0 10px',

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      // padding: '16px 0',
    },
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'start',
  },
  iconWhite: {
    padding: '2px',
    margin: '9px',
  },
  flag: {
    marginTop: '8px',
  },
  third: {
    width: '33.33%',
  },
  half: {
    width: '50%',
  },
  itemsLeft: {
    display: 'flex',
  },
  marginSupport: {
    display: 'flex',
    alignItems: 'end',
    margin: '10px 0',
  },
}))
