import { getAuthRoutes } from '@/routes/configs/auth'
import { getPrivateBookingRoutes, getPrivateRoutes } from '@/routes/configs/private'

export const getRootRoutes = (isLoggedIn: boolean, user: object | null): any => {
  return [
    //...getBookNewRoutes(isLoggedIn, user),
    //...getPublicRoutes(isLoggedIn, user),
    ...getPrivateBookingRoutes(isLoggedIn, user),
    ...getPrivateRoutes(isLoggedIn, user), // order is necessary (for index route go to private route)
    ...getAuthRoutes(isLoggedIn),
  ]
}
