import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { useEvents, useMixPanel } from '@/features/app/hooks'
import { RegisterModel } from '@/features/auth'
import { profileAPI } from '@/features/profile/api'
import { FormTypes } from '@/types'
import { authAPI } from '../../api'
import { RegisterDTO } from '../../api/dto'
import { authData } from '../data/slice'
import { AUTH_REGISTER_FAILED, AUTH_REGISTER_REQUEST, AUTH_REGISTER_SUCCESS } from './actionTypes'

export const authRegisterPA = createPromiseAction(
  AUTH_REGISTER_REQUEST,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_FAILED
)<RegisterDTO, RegisterModel, AxiosError<FormTypes.ValidationErrors>>()

function* register(action: PromiseAction<string, RegisterDTO, any>) {
  const mixPanel = useMixPanel()
  const events = useEvents()
  try {
    const { payload } = action
    const response: AxiosResponse = yield call(authAPI.register, action.payload)
    yield put(authData.setData({ isLoggedIn: true, token: response.data.token }))
    yield put(authRegisterPA.success({}))

    const profileResponse: AxiosResponse = yield call(profileAPI.getProfile)
    mixPanel.actions.identify(profileResponse.data?.data.id)
    mixPanel.actions.people.set({
      $first_name: profileResponse.data?.data.first_name,
      $last_name: profileResponse.data?.data.last_name,
      $email: profileResponse.data?.data.email,
      $language: payload.lang,
    })
    events.actions.register({ Type: 'Email/Password' })

    resolvePromiseAction(action, { ...response.data })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* registerSaga() {
  yield takeEvery(authRegisterPA.request, register)
}
